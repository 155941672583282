<template>
  <div class="project-tiles">
    <router-link class="project-tiles__tile"
                 v-for="project in projects"
                 :key="'project_'+project.id"
                 :style="getTileStyle(project)"
                 :to="{name: 'ingame_project', params: {id: project.id}}"
    >
      <div class="project-tiles__tile-title"><span>{{ project.name }}</span></div>
      <div class="project-tiles__tile-in-charge" v-if="project.inCharge">
        <div class="project-tiles__tile-in-charge-avatar"
             :style="{'background-image': `url(${getAvatar(project.inCharge)})`}"></div>
        <div class="project-tiles__tile-in-charge-username">{{ project.inCharge.userName }}</div>
        <div class="project-tiles__tile-in-charge-contributors" :title="getContributors(project)">+
          {{ project.contributors.length }}
        </div>
      </div>
      <div class="project-tiles__tile-state" :class="stateClasses(project)"><span>{{ getStateTitle(project) }}</span>
      </div>
      <div class="project-tiles__tile-project-type">
        <div class="project-tiles__tile-project-type-bg" :style="typeStyle(project.projectType)"></div>
        <span>{{ project.projectType.name }}</span>
      </div>
    </router-link>
  </div>
</template>

<script>
import { localizationMixin } from "../../../mixins/localization";

export default {
  name: "ProjectTiles",
  mixins: [localizationMixin],
  props: {
    projects: {
      type: Array,
      default: () => []
    },
  },
  data: () => ({
    states: []
  }),
  methods: {
    typeStyle(projectType) {
      const { color } = projectType;
      if (color) {
        return {
          'border-left': `5px solid ${color}`
        }
      }
      return {};
    },
    stateClasses(project) {
      return {
        'project-tiles__tile-state--concept': project.state === 'concept',
        'project-tiles__tile-state--in-progress': project.state === 'in-progress',
        'project-tiles__tile-state--on-hold': project.state === 'on-hold',
        'project-tiles__tile-state--testing': project.state === 'testing',
        'project-tiles__tile-state--cancelled': project.state === 'cancelled',
        'project-tiles__tile-state--ready': project.state === 'ready',
      }
    },
    getStateTitle(project) {
      const state = this.states.find(state => state.value === project.state);
      if (state) {
        return state.title;
      }
      return project.state;
    },
    getContributors(project) {
      return project.contributors.map(contributor => {
        return contributor.username;
      }).join(', ');
    },
    getTileStyle(project) {
      return {
        'background-image': project.projectImages && project.projectImages.length > 0 ? `url(${this.renderPoster(project.projectImages[0])})` : null
      }
    },
    getAvatar(user) {
      if (user && user.avatar) {
        return user.avatar
      }
      return 'https://cdn.hyperion-corporation.de/ui/placeholder_avatar.png';
    },
    renderPoster(projectImage) {
      if(projectImage.file.type === 'mp4') {
        return projectImage.file.thumbnailUri;
      }
      return projectImage.file.uri;
    },
  },
  created() {
    this.states = [
      { value: 'concept', title: this.__('entity.ingame.project.state.concept') },
      { value: 'in-progress', title: this.__('entity.ingame.project.state.in-progress') },
      { value: 'on-hold', title: this.__('entity.ingame.project.state.on-hold') },
      { value: 'testing', title: this.__('entity.ingame.project.state.testing') },
      { value: 'cancelled', title: this.__('entity.ingame.project.state.cancelled') },
      { value: 'ready', title: this.__('entity.ingame.project.state.ready') }
    ];
  }
}
</script>