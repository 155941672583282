<template>
  <div class="projects view">
    <app-header title="Ingame - Projects"/>
    <progress-linear v-if="loading" :indeterminate="true"/>
    <div class="control-bar" :class="{'control-bar--disabled': loading}">
      <div class="control-bar__info">
        <div class="control-bar__info-title">{{ __('common.total') }}:</div>
        <div class="control-bar__info-value">{{ pagination.total }}</div>
      </div>
      <div class="control-bar__search mr ml">
        <select-reference
            v-model="userId"
            :endpoint="'/community/user'"
            :field-icon="'avatar'"
            :field-title="'userName'"
            :nothing-selected-message="__('filterByUser')"
            :hide-info="true"
            @input="refreshDelayed(100)"
        />
      </div>
      <div class="control-bar__search mr">
        <select-reference
            v-model="projectTypeId"
            :endpoint="'/ingame/projects/projecttype'"
            :nothing-selected-message="__('filterByProjectType')"
            :hide-info="true"
            @input="refreshDelayed(100)"
        />
      </div>
      <div class="control-bar__search mr">
        <select-reference
            v-model="state"
            :force-available-items="states"
            :field-value="'value'"
            :field-title="'title'"
            :nothing-selected-message="__('filterByProjectState')"
            :hide-info="true"
            @input="refreshDelayed(100)"
        />
      </div>
      <text-field
          class="control-bar__search"
          v-model="searchTerm"
          :label="__('common.search_term')"
          max-length="50"
          :hide-details="true"
          :clearable="true"
          @keyup:enter="refreshDelayed(100)"
          @click:clear="refreshDelayed(100)"
      />
      <div class="control-bar__buttons">
        <div class="control-bar__button" :title="__('common.refresh')" @click="refresh" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/refresh.svg"
               alt="refresh"/>
        </div>
        <div class="control-bar__button"
             :title="__('common.add')" @click="addProject()" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/add.svg"
               alt="add"/>
        </div>
      </div>
    </div>
    <div class="main-content-padding">
      <pagination
          class="mt"
          :count-pages="pagination.pages"
          :total-visible="10"
          :page="pagination.page"
          :disabled="loading"
          @paginate="paginate"
      />
      <project-tiles class="mt" :projects="projects"/>
      <pagination
          class="mt"
          :count-pages="pagination.pages"
          :total-visible="10"
          :page="pagination.page"
          :disabled="loading"
          @paginate="paginate"
      />
    </div>
    <edit-project ref="editProject" @updated="refresh" @created="editProject"/>
  </div>
</template>

<script>
import apiSecured from '../../api/secured';
import DataTable from '../../components/DataTable';
import AppHeader from '../../components/AppHeader';
import ProjectTiles from '../../components/Ingame/Project/ProjectTiles';
import { notificationsMixin } from '../../mixins/notifications';
import { localizationMixin } from '../../mixins/localization';
import { paginationMixin } from "../../mixins/pagination";
import { pageTitleMixin } from "../../mixins/pageTitle";
import EditProject from "../../components/Ingame/EditProject";

export default {
  mixins: [notificationsMixin, localizationMixin, paginationMixin, pageTitleMixin],
  components: {
    AppHeader,
    DataTable,
    ProjectTiles,
    EditProject
  },
  data: () => ({
    loading: false,
    projects: [],
    userId: null,
    projectTypeId: null,
    state: null,
    states: []
  }),
  methods: {
    addProject() {
      this.$refs.editProject.showCreate({ inChargeId: this.$store.state.user.id });
    },
    async editProject(project) {
      await this.refresh();
      this.$refs.editProject.showEdit(project);
    },
    refreshDelayed(delay) {
      setTimeout(this.refresh, delay);
    },
    refresh() {
      this.loading = true;
      let params = this.getPaginationParams();
      params.userId = this.userId;
      params.projectTypeId = this.projectTypeId;
      params.state = this.state;
      this.$router.push({ name: this.$route.name, query: params }).catch(err => {
      });
      apiSecured.get('/ingame/projects/project', { params }).then(async (res) => {
        this.projects = await this.enrichRecords(res.data);
        this.updatePagination(res);
        this.loading = false;
        this.showInfoNotification(this.__('common.projects_loaded'));
      }).catch((error) => {
        this.loading = false;
        this.showErrorNotifications(error)
      });
    },
    async enrichRecords(projects) {
      if(projects.length === 0) {
        return projects;
      }
      const projectTypeIds = [];
      const projectIds = [];
      const inChargeIds = [];
      let projectTypes = [];
      let projectImages = [];
      let inCharges = [];

      projects.forEach(project => {
        projectIds.push(project.id);
        if (!projectTypeIds.includes(project.projectTypeId)) {
          projectTypeIds.push(project.projectTypeId);
        }
        if (!inChargeIds.includes(project.inChargeId)) {
          inChargeIds.push(project.inChargeId);
        }
      });

      await apiSecured.get(`/ingame/projects/projecttype/(${projectTypeIds.join(',')})`, {
        params: {
          fields: 'id,name,color'
        },
      }).then((res) => {
        projectTypes = res.data;
        this.showInfoNotification('Project types have been reloaded');
      }).catch((error) => {
        this.showErrorNotifications(error)
      });

      await apiSecured.get(`/community/user/(${inChargeIds.join(',')})`, {
        params: {
          fields: 'id,avatar,username'
        },
      }).then((res) => {
        inCharges = res.data;
        this.showInfoNotification('Users have been reloaded');
      }).catch((error) => {
        this.showErrorNotifications(error)
      });

      const params = {
        orderBy: 'sorting asc'
      }
      await apiSecured.get(`/ingame/projects/projectimage/by-projects/(${projectIds.join(',')})`, { params }).then((res) => {
        projectImages = res.data
        this.showInfoNotification('Project images have been reloaded');
      }).catch((error) => {
        this.showErrorNotifications(error)
      });

      const fileIds = projectImages.map(projectImage => projectImage.fileId);
      if(fileIds.length > 0) {
        await apiSecured.get(`/fal/file/(${fileIds.join(',')})`).then((res) => {
          projectImages = projectImages.map(projectImage => {
            projectImage.file = res.data.find(file => file.id === projectImage.fileId);
            return projectImage;
          });
          this.showInfoNotification('Files have been reloaded');
        }).catch((error) => {
          this.showErrorNotifications(error)
        });
      }

      projects = projects.map(project => {
        project.projectType = projectTypes.find(projectType => projectType.id === project.projectTypeId);
        project.projectImages = projectImages.filter(projectImage => projectImage.projectId === project.id);
        project.inCharge = inCharges.find(inCharge => project.inChargeId === inCharge.id);
        return project;
      });

      return projects;
    }
  },
  created() {
    this.setPageTitle(this.__('entity.common.projects'));
    this.states = [
      { value: 'concept', title: this.__('entity.ingame.project.state.concept') },
      { value: 'in-progress', title: this.__('entity.ingame.project.state.in-progress') },
      { value: 'on-hold', title: this.__('entity.ingame.project.state.on-hold') },
      { value: 'testing', title: this.__('entity.ingame.project.state.testing') },
      { value: 'cancelled', title: this.__('entity.ingame.project.state.cancelled') },
      { value: 'ready', title: this.__('entity.ingame.project.state.ready') }
    ];
    this.refresh();
  }
}
</script>
