<template>
  <div class="edit-player">
    <my-dialog :show="show" @close="close">
      <div class="card">
        <div class="card__header">
          <div class="card__title">{{ __('component.ingame.edit_project_type.title') }}</div>
          <div class="card__button" @click="close" v-slashes>
            <img src="https://cdn.hyperion-corporation.de/ui/svgs/cross.svg" alt="x"/>
          </div>
        </div>
        <div class="card__content" v-if="record">
          <progress-linear v-if="loading"/>
          <div class="layout layout--wrap">
            <div class="flex xs12 sm12 md6">
              <div class="layout layout--wrap">
                <div class="flex xs12">
                  <text-field
                      v-model="record.name"
                      :label="__('entity.common.name')"
                      :error-messages="errors.name"
                      maxlength="50"
                      :prepend-icon="isChanged('name') ? 'pencil' : null"
                      @input="registerChange('name')"
                  />
                </div>
                <div class="flex xs12">
                  <label>{{ __('entity.common.project_type') }}</label>
                  <select-reference
                      v-model="record.projectTypeId"
                      :endpoint="'/ingame/projects/projecttype'"
                      :nothing-selected-message="__('common.no_project_type_selected')"
                      :prepend-icon="isChanged('projectTypeId') ? 'pencil' : ''"
                      @input="registerChange('projectTypeId')"
                  />
                </div>
                <div class="flex xs12">
                  <label>{{ __('entity.ingame.project.in_charge') }}</label>
                  <select-reference
                      v-model="record.inChargeId"
                      :endpoint="'/community/user'"
                      :field-title="'userName'"
                      :nothing-selected-message="__('common.no_user_selected')"
                      :prepend-icon="isChanged('inChargeId') ? 'pencil' : ''"
                      @input="registerChange('inChargeId')"
                  />
                </div>
                <div class="flex xs12">
                  <label>{{ __('entity.ingame.project.state') }}</label>
                  <single-select
                      :items="states"
                      v-model="record.state"
                      :append-icon="isChanged('state') ? 'pencil' : null"
                      @input="registerChange('state')"
                  />
                </div>
                <div class="flex xs12 mt">
                  <label>{{ __('entity.ingame.project.contributors') }}</label>
                  <multi-select
                      v-model="record.contributorsUserIds"
                      :endpoint="'/community/user'"
                      :field-title="'userName'"
                      :field-icon="'avatar'"
                      :field-icon-default="'https://cdn.hyperion-corporation.de/ui/placeholder_avatar.png'"
                      :prepend-icon="isChanged('contributorsUserIds') ? 'pencil' : ''"
                      @input="registerChange('contributorsUserIds')"
                  />
                </div>
                <div class="flex xs12 mt">
                  <label>{{ __('entity.ingame.project.access') }}</label>
                  <multi-select
                      v-model="record.projectAccessesRoleIds"
                      :endpoint="'/identity/role'"
                      :field-icon="null"
                      :prepend-icon="isChanged('projectAccessesRoleIds') ? 'pencil' : ''"
                      @input="registerChange('projectAccessesRoleIds')"
                  />
                </div>
                <div class="flex xs12 mt bodytext">
                  <ckeditor v-model="record.description"
                            :title="__('entity.common.description')"
                            :editor="editor"
                            :config="editorConfig"
                            @input="registerChange('description')"></ckeditor>
                </div>
                <div class="flex xs12">
                  <div class="layout">
                    <div class="flex xs6 mt">
                      <div class="btn btn--success" :loading="loading"
                           :disabled="changedProperties.length === 0"
                           @click="update" v-slashes>{{ __('common.save_changes') }}
                      </div>
                    </div>
                    <div class="flex xs6 mt">
                      <div class="btn btn--primary" @click="close" v-slashes>{{
                          __('common.close')
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex xs12 sm12 md6">
              <edit-project-images ref="editOrganizationMemberships" :project-id="record.id"/>
            </div>
          </div>
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
import { localizationMixin } from '../../mixins/localization';
import { editFormMixin } from "../../mixins/editForm";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import EditProjectImages from "./EditProject/EditProjectImages";

export default {
  components: { EditProjectImages },
  mixins: [localizationMixin, editFormMixin],
  data: () => ({
    editor: ClassicEditor,
    endpoint: '/ingame/projects/project',
    emptyRecord: {
      id: null,
      name: '',
      inChargeId: null,
      description: '',
      projectTypeId: null,
      state: null,
      contributors: [],
      projectAccesses: [],
      projectAccessesRoleIds: [],
      contributorsUserIds: [],
      createdAt: '---',
      updatedAt: '---'
    },
    states: [],
    editorConfig: {
      toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList'],
      heading: {
        options: [
          { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
          { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
          { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' }
        ]
      }
    }
  }),
  methods: {
    postProcess() {
      this.record.contributorsUserIds = this.record.contributors.map(contributor => {
        return contributor.userId;
      });
      this.record.projectAccessesRoleIds = this.record.projectAccesses.map(projectAccess => {
        return projectAccess.roleId;
      });
    },
  },
  created() {
    this.states = [
      { value: 'concept', title: this.__('entity.ingame.project.state.concept') },
      { value: 'in-progress', title: this.__('entity.ingame.project.state.in-progress') },
      { value: 'on-hold', title: this.__('entity.ingame.project.state.on-hold') },
      { value: 'testing', title: this.__('entity.ingame.project.state.testing') },
      { value: 'cancelled', title: this.__('entity.ingame.project.state.cancelled') },
      { value: 'ready', title: this.__('entity.ingame.project.state.ready') }
    ];
  }
}
</script>